import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC3ljqru2JuCiFF2pwJT8sJoKkuRNNPvzE",
  // authDomain: "instagree-67ec5.firebaseapp.com",
  authDomain: "app.instagree.io",
  projectId: "instagree-67ec5",
  storageBucket: "instagree-67ec5.appspot.com",
  messagingSenderId: "1005001040562",
  appId: "1:1005001040562:web:ac4ad52c8274760cb35fab",
  measurementId: "G-FWGT7QYM3K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);

export { auth, firestore, analytics };
export default app;
