import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getRedirectResult,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import React, { useEffect, useState } from "react";

import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const SignInPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const checkRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          console.log("Sign In successful", result.user);
          navigate("/");
        }
      } catch (error) {
        console.error("Error handling redirect result:", error);
        setError("An error occurred during sign in. Please try again.");
      }
    };

    checkRedirectResult();
  }, [navigate]);

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (error) {
      console.error("Error signing in:", error);
      setError("Invalid email or password. Please try again.");
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithRedirect(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError("An error occurred during Google sign in. Please try again.");
    }
  };

  const handleAppleSignIn = () => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    signInWithRedirect(auth, provider).catch((error) => {
      console.error("Apple Sign In Error:", error.code, error.message, error);
      setError("An error occurred during Apple sign in. Please try again.");
    });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Sign In
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleEmailSignIn}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Sign In
          </Button>
        </form>
        <Divider sx={{ my: 4 }}>OR</Divider>
        <Button
          onClick={handleGoogleSignIn}
          variant="outlined"
          fullWidth
          sx={{
            mb: 4,
          }}
        >
          Sign in with Google
        </Button>
        <Button
          onClick={handleAppleSignIn}
          variant="outlined"
          fullWidth
          sx={{
            mb: 4,
          }}
        >
          Sign in with Apple
        </Button>
      </Box>
    </Container>
  );
};

export default SignInPage;
