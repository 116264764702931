import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
}

const ProfilePage: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(firestore, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProfile(docSnap.data() as UserProfile);
        }
      }
      setLoading(false);
    };

    fetchProfile();
  }, []);

  if (loading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  if (!profile) return <Typography>No profile found.</Typography>;

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
        <Typography>
          Name: {profile.firstName} {profile.lastName}
        </Typography>
        <Typography>Email: {profile.email}</Typography>
      </Box>
    </Container>
  );
};

export default ProfilePage;
