import { Container, Typography } from "@mui/material";

import React from "react";

const HomePage: React.FC = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Welcome to Instagree!
      </Typography>
      <Typography>Please download the app to create agreements.</Typography>
      <Typography>
        One person should create an agreement using the mobile app. The others
        can then sign it through the QR code link on this website.
      </Typography>
    </Container>
  );
};

export default HomePage;
