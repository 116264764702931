import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth";

import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import logo from "../assets/logo-white.png";

interface HeaderProps {
  user: any;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const menuItems = user
    ? [
        { text: "Home", link: "/" },
        { text: "Agreements", link: "/agreements" },
        { text: "Profile", link: "/profile" },
        { text: "Sign Out", action: handleSignOut },
      ]
    : [
        { text: "Sign In", link: "/signin" },
        { text: "Sign Up", link: "/signup" },
      ];

  const renderMenuItems = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={item.link ? RouterLink : "div"}
            to={item.link || undefined}
            onClick={item.action || undefined}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          marginBottom: "16px",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <img
              src={logo}
              alt="Instagree Logo"
              style={{ height: "60px", verticalAlign: "middle" }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {renderMenuItems()}
      </Drawer>
    </>
  );
};

export default Header;
