import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF326A",
    },
    secondary: {
      main: "#008080",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#888888",
    },
    error: {
      main: "#FF0000",
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "Arial, sans-serif",
    h1: {
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    h2: {
      fontSize: "18px",
      opacity: 0.7,
    },
    body1: {
      fontSize: "16px",
    },
  },
  spacing: (factor: number) => `${4 * factor}px`,
});

export default theme;
