import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

import { firestore } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

interface Agreement {
  id: string;
  title: string;
  status: "pending" | "completed" | "cancelled";
  createdAt: string;
  pdfUrl?: string;
  parties: Record<string, { userId: string }>;
}

const AgreementPage: React.FC = () => {
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [refreshing, setRefreshing] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  const fetchAgreements = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (!currentUser?.uid) return;

    setRefreshing(true);
    try {
      const agreementsRef = collection(firestore, "agreements");
      const agreementsSnapshot = await getDocs(
        query(agreementsRef, orderBy("createdAt", "desc"))
      );

      const fetchedAgreements = agreementsSnapshot.docs
        .filter((doc) => {
          const data = doc.data();
          const parties = data.parties || {};
          return Object.values(parties).some(
            (party) => (party as any).userId === currentUser.uid
          );
        })
        .map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Agreement)
        );

      setAgreements(fetchedAgreements);
    } catch (error) {
      console.error("Error fetching agreements:", error);
    } finally {
      setRefreshing(false);
    }
  }, [auth]);

  useEffect(() => {
    fetchAgreements();
  }, [fetchAgreements]);

  if (refreshing) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Your Agreements
      </Typography>
      {agreements.length === 0 ? (
        <Typography>No agreements found.</Typography>
      ) : (
        agreements.map((agreement) => (
          <Card key={agreement.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">{agreement.title}</Typography>
              <Typography color="textSecondary">
                Status: {agreement.status}
              </Typography>
              <Typography color="textSecondary">
                Created: {new Date(agreement.createdAt).toLocaleDateString()}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/agreement/${agreement.id}`)}
                  sx={{ mr: 2 }}
                >
                  View Details
                </Button>
                {agreement.pdfUrl && (
                  <Link
                    href={agreement.pdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="outlined">View PDF</Button>
                  </Link>
                )}
              </Box>
            </CardContent>
          </Card>
        ))
      )}
    </Container>
  );
};

export default AgreementPage;
