import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAuth, getRedirectResult, onAuthStateChanged } from "firebase/auth";

import AgreementPage from "./pages/AgreementPage";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import theme from "./theme/theme";

function App() {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          console.log("Sign In successful", result.user);
          // You might want to update user state here if needed
        }
      } catch (error) {
        console.error("Error handling redirect result:", error);
        // Handle error (show message to user)
      }
    };

    handleRedirectResult();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header user={user} />
        <Routes>
          <Route
            path="/"
            element={user ? <HomePage /> : <Navigate to="/signin" />}
          />
          <Route
            path="/signin"
            element={!user ? <SignInPage /> : <Navigate to="/" />}
          />
          <Route
            path="/signup"
            element={!user ? <SignUpPage /> : <Navigate to="/" />}
          />
          <Route
            path="/profile"
            element={user ? <ProfilePage /> : <Navigate to="/signin" />}
          />
          <Route
            path="/agreements"
            element={user ? <AgreementPage /> : <Navigate to="/signin" />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
